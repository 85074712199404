import React from 'react'
import { PreRegistrationComplete } from 'tf-checkout-react'
import Head from '../../components/head'
import Layout from '../../components/layout'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import Logo from '../../assets/images/mana_footer.png'
import './style.scss'
import { isBrowser } from '../../utils/common/isBrowser'

setTfCheckoutReactConfigs()

const getEventId = () => {
  if (isBrowser) {
    const params = new URL(window.location.href).searchParams
    const eventId = params.get('event_id')
    return eventId || ''
  }
  return ''
}

const PreRegistrationCompletePage = () => {

  const onConfirmationPageError = () => {
    if (isBrowser) {
      window.location.href = "/";
    }
  };

  return (
    <>
      <Head title="Pre Registration Complete Page" />
      <Layout>
        <div className="pre_reg_page">
          <PreRegistrationComplete 
            onGetConfirmationDataError={onConfirmationPageError} 
            logo={Logo} 
            eventId={getEventId()} 
          />
        </div>
      </Layout>
    </>
  )
}

export default PreRegistrationCompletePage
